import React from 'react';
import ReactDOM from 'react-dom';
import App from './App'; // Make sure this path is correct
import './index.css';    // Or './App.css' if applicable

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')  // Ensure your 'index.html' contains an element with id 'root'
);