import React, { useState } from 'react';
import {
  Container,
  TextField,
  Button,
  Typography,
  CircularProgress,
  Card,
  CardContent,
} from '@mui/material';

const REGION = 'canada';

function App() {
  const [query, setQuery] = useState('');
  const [answer, setAnswer] = useState(null);
  const [references, setReferences] = useState([]);
  const [loading, setLoading] = useState(false); // Added loading state
  const [error, setError] = useState(null); // Added error state

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (query) {
      setLoading(true);
      setError(null);
      setAnswer(null);
      setReferences([]);
      try {
        // Send the URL request
        const url = `/api/chat?query=${encodeURIComponent(query)}&region=${encodeURIComponent(REGION)}`;
        const response = await fetch(
          url,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            }
          }
        );

        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        setAnswer(data.answer);
        setReferences(data.references);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('An error occurred while fetching data.');
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Container maxWidth="md" style={{ paddingTop: '50px' }}>
      <Typography variant="h4" gutterBottom>
        Ask Questions About Canadian Laws
      </Typography>
      <form onSubmit={handleSubmit} style={{ display: 'flex', gap: '10px' }}>
        <TextField
          label="Ask a question"
          variant="outlined"
          fullWidth
          value={query}
          onChange={handleInputChange}
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={loading}
        >
          Submit
        </Button>
      </form>
      {loading && <CircularProgress style={{ marginTop: '20px' }} />}
      {error && (
        <Typography color="error" style={{ marginTop: '20px' }}>
          {error}
        </Typography>
      )}
      {answer && (
        <Card style={{ marginTop: '20px' }}>
          <CardContent>
            <Typography variant="h5">Answer</Typography>
            <Typography variant="body1">{answer}</Typography>
          </CardContent>
        </Card>
      )}
      {references.length > 0 && (
        <Card style={{ marginTop: '20px' }}>
          <CardContent>
            <Typography variant="h5">Relevant Passages</Typography>
            {references.map((text, index) => (
              <Typography key={index} variant="body2" paragraph>
                {text}
              </Typography>
            ))}
          </CardContent>
        </Card>
      )}
    </Container>
  );
}

export default App;
